class ImageTextColumn {
    image="";
    heading1="";
    heading2="";
    bodyText="";
    button1LinkText="";
    button1URL="";
    button2LinkText="";
    button2URL="";
    formName="";
    [key:string]:any;
}